@import url('https://fonts.googleapis.com/css2?family=Silkscreen:wght@400;700&display=swap'); /*Game*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700;800&display=swap'); /*Open Sans*/

* {
  
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  --background-color: #1b2b34;
  --font-color: #FCFBF4;
  --hover-color: #FFC857;
  --secondary-hover-color: #5f6ffa;
  --mid-color: #a36ce3;
  --game-font: 'Silkscreen', sans-serif;
  --font: 'Open Sans', sans-serif;
}

/* GENERICS */

body {
  text-align: center;
  background-color: var(--background-color);
  color: var(--font-color);
  font-family: 'Roboto', sans-serif;
}

Section {
  padding: 60px 0px;
}

.container {
  padding: 92px 0px;
}

.row {
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  width: 100%;
  max-width: 300px;
}

.purple {
  color: var(--mid-color);
}

.section_header {
  margin: 8px;
  font-size: 36px;
}

.section_subheader {
  margin: 12px;
  font-size: 22px;
}

/* NAVIGATION */
.nav_container {
  padding: 60px 20px 20px 20px;
  max-width: 1200px;
}

.nav_links {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  max-height: 100px;

}

.nav_item {
  transition: all 0.3s ease-in-out;
  list-style: none;
}

.nav_item:not(:nth-child(3)):hover {
  cursor: pointer;
  transform: scale(1.2);
}

.middle_item:hover {
  filter: drop-shadow(0px 0px 10px var(--hover-color));
}

.nav_link {
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  color: var(--font-color);
  font-family: var(--font);
  font-size: 24px;
  font-weight: 700;
}

.nav_link:hover {
  color: var(--hover-color);
}

/* LANDING*/

#Landing {
  padding-bottom: 0px;
}

.download_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.download_wrapper:hover .arrow_right {
  transform: translateX(30px);
}

.download_wrapper:hover .arrow_left {
  transform: translateX(-30px);
}

.arrow {
  transition: all 0.3s ease-in-out;
  margin: 0px;
  width: 50px;
  height: 50px;
}

.download_link {
  text-decoration: none;
  color: var(--font-color);
  font-family: var(--font);
  font-size: 24px;
  font-weight: 700;
  padding: 20px 40px;
  margin: 0px;
  transition: all 0.3s ease-in-out;
}

.download_link:hover {
  transform: scale(.9);
  color: var(--hover-color);
}

/* FOOTER */

footer {
  padding: 60px 0px;
}

.footer_links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer_item {
  transition: all 0.3s ease-in-out;
  list-style: none;
  margin: 16px;
}

.footer_item:hover {
  transform: scale(0.9);
}

.footer_link {
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  color: var(--font-color);
  font-family: var(--font);
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
}

.footer_link:hover {
  color: var(--secondary-hover-color);
}

.logo_link {
  transition: all 0.3s ease-in-out;
  height: 50px;
  width: 50px;
  margin: 12px;
}

.logo_link:hover {
  transform: scale(1.1);
}

/* SHOWCASE */

#Showcase {
  padding-top: 0px;
}

.travel_wrapper {
  display: flex;
  flex-direction: row;
}

.travel {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.showcase_image {
  padding: 12px;
}

.class_wrapper {
  display: flex;
  flex-direction: row;
}

.combat_wrapper {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 16px;
}

.intro {
  font-size: 18px;
  letter-spacing: 0.1rem;
}
